import React, { useRef, useLayoutEffect } from "react";
import office_image_1 from "../../images/office_image_1.jpg";
import dedicated_desk_2 from "../../images/dedicated_desk_2.jpg";
import flex_desk_2 from "../../images/flex_desk_2.jpg";
import new_meeting_room from "../../images/new_meeting_room.jpg";
import entrance from "../../images/entrance.jpg";
import private_office_4 from "../../images/private_office_4.jpg";

import office_image_1_webp from "../../images/webp/office_image_1.webp";
import dedicated_desk_2_webp from "../../images/webp/dedicated_desk_2.webp";
import flex_desk_2_webp from "../../images/webp/flex_desk_2.webp";
import new_meeting_room_webp from "../../images/webp/new_meeting_room.webp";
import entrance_webp from "../../images/webp/entrance.webp";
import private_office_4_webp from "../../images/webp/private_office_4.webp";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "gatsby";

function Offering(props) {
  return (
    <div className="mr-8 offering-image transition-all transform md:hover:scale-105">
      <Link to={props.to}>
      
      <picture>
        <source srcSet={props.webp} type="image/webp" />
        <source srcSet={props.image} type="image/jpeg" />
        <img src={props.image} className="w-100" />
      </picture>
      <h3 className="text-xl pt-4">{props.title}</h3>
        <p className="underline text-lg pt-2">Learn more</p>
      </Link>
    </div>
  );
}

export default function OurOfferings() {
  gsap.registerPlugin(ScrollTrigger);

  const slider = useRef(null);
  const refDiv = useRef(null);

  useLayoutEffect(() => {
    var window_spacing = 35;
    var tiles = 4;
    var tl = undefined;

    function handleResize() {
      if (window.innerWidth < 768 || window.innerHeight < 768) {
        window_spacing = 16;
        tiles = 5;
      } else {
        window_spacing = 35;
        tiles = 4;
      }
    }

    handleResize();

    if (refDiv.current && slider.current) {
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: refDiv.current,
          scrub: 2,
          pin: true,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          ease: "none"

        },
      });

      tl.fromTo(
        slider.current,
        { x: 0 },
        { x: `-${window_spacing * tiles + tiles * 2}rem`, duration: 1 },
        0
      );
    } else {
      window.console.log("refDiv.current: ", refDiv.current);
      window.console.log("slider.current: ", slider.current);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      if (tl != undefined) {
        tl.kill();
        window.console.log("Gsap TL killed");
      }
    };
  }, []);

  // Test

  return (
    <section className="bg-white text-black relative overflow-hidden flex items-center my-auto">
      <div className="max-w-7xl mx-auto">
        <div
          className="py-16 md:py-24 px-4 flex items-center"
          ref={refDiv}
          style={{ minHeight: "100vh" }}
        >
          <div className="w-full">
            <h2 className="uppercase text-black text-xl">Our Offerings</h2>
            <p className="text-black text-3xl md:text-4xl pt-4">
              From desks & office <br />
              to meeting rooms
            </p>

            <div ref={slider} className="flex flex-row pt-16 w-screen">
              <Offering
                image={office_image_1}
                webp={office_image_1_webp}
                title={`Private Offices`}
                to={`/private-offices`}
              />
              <Offering
                image={dedicated_desk_2}
                webp={dedicated_desk_2_webp}
                title={`Dedicated Desks`}
                to={`/dedicated-desks`}
              />
              <Offering
                image={flex_desk_2}
                webp={flex_desk_2_webp}
                title={`Flexible Workspaces`}
                to={`/flexi-desk`}
              />
              <Offering
                image={new_meeting_room}
                webp={new_meeting_room_webp}
                title={`Meeting Rooms`}
                to={`/meeting-rooms`}
              />
              <Offering
                image={entrance}
                webp={entrance_webp}
                title={`Virtual Office`}
                to={`/virtual-office`}
              />
              <Offering
                image={private_office_4}
                webp={private_office_4_webp}
                title={`Daily Office`}
                to={`/daily-office`}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
